import React from 'react';
import heroThumbOne from '../../assets/images/hero-thumb-1.png';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';

function HeroHomeRtl({ className }) {
    return (
        <>
            <section className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content text-right">
                                <span></span>
                                <h1 className="appie-title">
                                    נותנים הזדמנות שניה לעודפי הבניה
                                </h1>
                                <p>
                                סיימתם לבנות את הבית ונשארתם עם חומרים מיותרים שתרצו להיפטר מהם? רכשתם משהו, התחרטתם, ואין אפשרות להחזירו לחנות?
                                    בינוי עודף מציעה אפליקציה המאפשרת לכם להציל עודפי בניה שלא שומשו, לפני שהם הופכים לפסולת בניה.
                                </p>
                                <ul>
                                    <li>
                                        <a href="#register">
                                            <i className="fab fa-apple" /> הורד ל-iOS
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="#register">
                                            <i className="fab fa-google-play" /> הורד ל-Android
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} alt="" />
                                </div>
                                {/* <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
            </section>
        </>
    );
}

export default HeroHomeRtl;
