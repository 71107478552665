import React from 'react';
import IconOne from '../../assets/images/icon/1.png';
import IconTwo from '../../assets/images/icon/2.png';
import IconThree from '../../assets/images/icon/3.png';

function ServicesHomeRtl({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                            בענף הבנייה יש עודפים רבים של חומרי בנייה שאין בהם צורך יותר בסיום הפרויקט וכתוצאה הם נזרקים וגורמים לבזבוז כספי ומניעת חיסכון. חומרים כגון ריצוף, צנרת, אלמנטים מבטון, מעקות, ברזל, ועוד חומרים יקרים שיכולים להיות שימושיים לפרויקטים אחרים.
                            </h3>
                            {/* <p>לפי The World Counts</p> */}
                        </div>
                    </div>
                </div>
                <div className="row center">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">חברתי</h4>
                            <p>אנחנו מבזבזים 2.5 מיליארד טון אוכל בשנה, בזמן שיש 828 מיליון אנשים רעבים בכל העולם בכל יום.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">כלכלי</h4>
                            <p>23 מיליארד שקלים מתבזבזים עקב בזבוז המזון כל שנה בישראל.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">איכות הסביבה</h4>
                            <p>פסולת מזון אחראית ל-10% מפליטת גזי חממה (זה יותר מכל תעשיית התעופה).</p>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">دعم</h4>
                            <p>Mucker الجص التافه كل ماتي موريش.</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeRtl;
