import React from 'react';
import blogOne from '../../assets/images/blog-1.png';
import blogTwo from '../../assets/images/blog-2.png';
import blogThree from '../../assets/images/blog-3.png';

function BlogHomeRtl({ className }) {
    return (
        <>
            <section className={`appie-blog-area pt-90 pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">בואו לגלות את הערך בעודפי המזון שלכם</h3>
                                <p>נשארתם עם אוכל שלא הספקתם למכור בסוף היום? אנחנו יכולים לעזור! <br />
                                    הצטרפו לאלפי העסקים המשתמשים ב-Leftover כדי להעביר את העודפים שלכם היישר לידיים של לקוחות מרוצים ולתת לאוכל טוב הזדמנות שנייה.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item text-right mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blogOne} alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                    </div>
                                    <h3 className="title">
                                       הפוך את האוכל העודף שלך להכנסה נוספת במאמץ מינימלי.
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item text-right mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="thumb">
                                    <img src={blogTwo} alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                    </div>
                                    <h3 className="title">
                                        הגדל את החשיפה ומשוך לקוחות חדשים באפליקציה ומחוצה לה.
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item text-right mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="thumb">
                                    <img src={blogThree} alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                    </div>
                                    <h3 className="title">
                                        צמצם את הפסולת שלך ועזור לסביבה.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogHomeRtl;
