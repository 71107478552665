import React from 'react';
import thumb from '../../assets/images/traffic-thumb.png';

function TrafficHomeRtl({ className }) {
    return (
        <section className={`appie-traffic-area pt-140 pb-180 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-right mb-4">
                            <span></span>
                            <h3 className="title">לאכול טוב ולעשות טוב</h3>
                            <p>
                            רעבים? עם אפליקציית Leftover, אתם יכולים לאכול טוב, לחסוך ולהשפיע.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-md-6">
                                <div className="appie-traffic-service text-right mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">מצילים אוכל מלהזרק לאשפה</h5>
                                    {/* <p>פאראגרף</p> */}
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="appie-traffic-service text-right item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">להנות בחצי מחיר או יותר</h5>
                                    {/* <p></p> */}
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="appie-traffic-service text-right item-3 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">חוסכים באשפה ושומרים על איכות הסביבה</h5>
                                    {/* <p></p> */}
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="appie-traffic-service text-right item-4 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">בתי העסק האהובים עליכם או פשוט לגלות מקומות חדשים</h5>
                                    {/* <p></p> */}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="traffic-btn mt-50">
                                    <a className="main-btn" href="#register">
                                        להרשמה <i className="fal fa-arrow-left" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default TrafficHomeRtl;
